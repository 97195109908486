import { useCallback, useEffect, useState } from 'react';

import { AuthContext } from '../context';

export const AuthProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(null);
  const [isUserRoleLoading, setIsUserRoleLoading] = useState(true);

  const fetchDB = useCallback(async () => {
    setIsUserRoleLoading(true);

    try {
      const response = await fetch('/db', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: '', password: '' }),
      });

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      const isManagerToNumber = Number(data.isManager);
      setUserRole(isManagerToNumber);
      setIsUserRoleLoading(false);
    } catch {
      setUserRole(null);
      setIsUserRoleLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDB();
  }, [fetchDB]);

  const hasUserRole = userRole !== null;

  return (
    <AuthContext.Provider
      value={{ isUserRoleLoading, hasUserRole, userRole, setUserRole }}
    >
      {children}
    </AuthContext.Provider>
  );
};
