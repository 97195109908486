import Cookies from 'js-cookie';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import footerlogo from '../assets/img/acccelarateIQ.png';
import prospectiqlogo from '../assets/img/prospectiq-logo.svg';
import { useAuthContext } from '../hooks';

export const CallerLayout = ({ children, twilioDevice }) => {
  const navigate = useNavigate();
  const { setUserRole } = useAuthContext();
  const [show, setshow] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logout = async () => {
    try {
      const response = await fetch('/logout');
      if (response.status !== 200) throw new Error();
      setUserRole(null);
      navigate('/login', { replace: true });
    } catch {
      toast.error("Can't logout. Please try again!", {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="h-full text-primary">
      <div className="app flex h-full flex-col lg:flex-row">
        {/*<div className="absolute inset-0">*/}
        {/*	<Lottie play*/}
        {/*			style={{width: "100%", height: "100%"}} animationData={loti}></Lottie>*/}

        {/*</div>*/}
        {/* <!-- Header / Sidebar --> */}
        <header className="w-full bg-primary bg-cover bg-center bg-no-repeat py-4 lg:h-[100vh] xl:w-56 lg:w-72 lg:py-8">
          <div className="flex h-full justify-between gap-10 px-5 lg:flex-col">
            <Link to="/caller-dashboard" className="block">
              <img
                src={prospectiqlogo}
                alt="ProspectIQ Logo"
                className="h-8 lg:h-10"
              />
            </Link>
            <ul className="hidden flex-1 space-y-1.5 lg:block">
              {Cookies.get('showNav') ? (
                <>
                  <li>
                    <a
                      className="group cursor-pointer flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                      onClick={() => navigate('/mdashboard')}
                    >
                      <span className="text-secondary transition group-hover:text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                        >
                          <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                          <path d="M9 22V12h6v10" />
                        </svg>
                      </span>
                      <span>Manager Dashboard</span>
                    </a>
                  </li>
                </>
              ) : null}

              <li className="cursor-pointer">
                <span
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                  onClick={() => {
                    if (twilioDevice) {
                      twilioDevice.disconnectAll();
                    }
                    navigate('/caller-dashboard');
                  }}
                >
                  <span className="text-secondary transition group-hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                      <path d="M9 22V12h6v10" />
                    </svg>
                  </span>
                  <span>Dashboard</span>
                </span>
              </li>
              <li className="cursor-pointer">
                <span
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                  onClick={() => navigate('/call-processing')}
                >
                  <span className="text-secondary transition group-hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      className="h-5 w-5"
                    >
                      <path d="M10 0a10 10 0 1 0 10 10A10.01 10.01 0 0 0 10 0Zm8.426 9.23h-2.322a6.163 6.163 0 0 0-5.335-5.334V1.574a8.474 8.474 0 0 1 7.657 7.657Zm-9.195 0H5.45a4.624 4.624 0 0 1 3.78-3.78v3.78Zm0 1.54v3.78a4.624 4.624 0 0 1-3.781-3.78h3.78Zm1.538 0h3.781a4.624 4.624 0 0 1-3.78 3.78v-3.78Zm0-1.54V5.45a4.624 4.624 0 0 1 3.781 3.78h-3.78ZM9.231 1.575v2.322a6.164 6.164 0 0 0-5.335 5.335H1.574a8.474 8.474 0 0 1 7.657-7.657ZM1.574 10.77h2.322a6.163 6.163 0 0 0 5.335 5.335v2.322a8.475 8.475 0 0 1-7.657-7.657Zm9.195 7.657v-2.322a6.163 6.163 0 0 0 5.335-5.335h2.322a8.474 8.474 0 0 1-7.657 7.657Z" />
                    </svg>
                  </span>
                  <span>Start Calling</span>
                </span>
              </li>
            </ul>

            <div className="hidden lg:block">
              <span
                className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                onClick={() => toggleDropdown()}
              >
                <span
                  href="https://prospectiq.ai/file-upload/"
                  target="_blank"
                  className="text-secondary transition group-hover:text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                  </svg>
                </span>
                <span>Help</span>
              </span>
              <div
                className={`${isDropdownOpen ? 'block ml-10 transition-all duration-300 ease-in-out' : 'hidden'}`}
              >
                <a
                  className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-t border-white text-xs hover:text-white cursor-pointer"
                  href="https://prospectiq.ai/getting-started-guide/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Getting Started Guide
                </a>
                <a
                  className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                  href="https://prospectiq.ai/how-to-videos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  How-to Videos
                </a>
                <a
                  className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                  href="https://prospectiq.ai/help-contact/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Need More Help?
                </a>
                <a
                  className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                  href="https://prospectiq.ai/product-news-updates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Product News & Updates
                </a>
              </div>

              <a
                className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                href="https://prospectiq.ai/file-upload/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-secondary transition group-hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </span>
                <span>Update ICP</span>
              </a>
              <a
                className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                href="https://subscription.prospectiq.ai/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-secondary transition group-hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </span>
                <span>Manage Plan</span>
              </a>
              <span
                className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                onClick={logout}
              >
                <span className="text-secondary transition group-hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                    />
                  </svg>
                </span>
                <span>Sign Out</span>
              </span>
              <div className="px-2.5 py-2">
                <img src={footerlogo} alt="" className="h-5" />
              </div>
            </div>

            {/* <!-- Navigation Toggle --> */}
            <button
              type="button"
              onClick={() => setshow(!show)}
              className="text-white/70 transition hover:text-white lg:hidden cursor-pointer"
              data-hs-overlay="#mobile-sidebar"
              aria-controls="mobile-sidebar"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle Navigation</span>
              <svg
                className="h-9 w-9 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </button>
            {/* <!-- End Navigation Toggle --> */}
          </div>
          {/* <!-- Sidebar Mobile  --> */}
          <div
            id="mobile-sidebar"
            className={`hs-overlay fixed bottom-0 start-0 top-0 z-[60]  w-64  transform overflow-y-auto bg-primary transition-all duration-300 ${show ? 'translate-x-0' : '-translate-x-full'} [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar]:w-2`}
          >
            <div className="flex h-full flex-col justify-between gap-5 p-5">
              <div className="">
                <span className="block">
                  <img
                    src={prospectiqlogo}
                    alt="ProspectIQ Logo"
                    className="h-8 lg:h-10 cursor-pointer"
                  />
                </span>
              </div>
              <nav
                className="hs-accordion-group mt-3 flex w-full flex-1 flex-col flex-wrap"
                data-hs-accordion-always-open
              >
                <ul className="flex-1 space-y-1.5">
                  <li>
                    <span
                      className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                      onClick={() => {
                        navigate('/caller-dashboard');
                      }}
                    >
                      <span className="text-secondary transition group-hover:text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                        >
                          <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                          <path d="M9 22V12h6v10" />
                        </svg>
                      </span>
                      <span>Dashboard</span>
                    </span>
                  </li>
                  <li>
                    <span
                      className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                      onClick={() => navigate('/call-processing')}
                    >
                      <span className="text-secondary transition group-hover:text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="h-5 w-5"
                          viewBox="0 0 24 24"
                        >
                          <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                          <path d="M9 22V12h6v10" />
                        </svg>
                      </span>
                      <span>Start Calling</span>
                    </span>
                  </li>
                </ul>
              </nav>
              <div className="">
                <span
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                  onClick={() => toggleDropdown()}
                >
                  <span
                    href="https://prospectiq.ai/file-upload/"
                    target="_blank"
                    className="text-secondary transition group-hover:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                      />
                    </svg>
                  </span>
                  <span>Help</span>
                </span>
                <div
                  className={`${isDropdownOpen ? 'block ml-10 transition-all duration-300 ease-in-out' : 'hidden'}`}
                >
                  <a
                    className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-t border-white text-xs hover:text-white cursor-pointer"
                    href="https://prospectiq.ai/getting-started-guide/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Getting Started Guide
                  </a>
                  <a
                    className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                    href="https://prospectiq.ai/how-to-videos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    How-to Videos
                  </a>
                  <a
                    className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                    href="https://prospectiq.ai/help-contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Need More Help?
                  </a>
                  <a
                    className="group flex items-center gap-2 py-2 font-medium text-white/50 border-b border-white text-xs hover:text-white cursor-pointer"
                    href="https://prospectiq.ai/product-news-updates/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Product News & Updates
                  </a>
                </div>

                <a
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                  href="https://prospectiq.ai/file-upload/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-secondary transition group-hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </span>
                  <span>Update ICP</span>
                </a>
                <a
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white cursor-pointer"
                  href="https://subscription.prospectiq.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="text-secondary transition group-hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </span>
                  <span>Manage Plan</span>
                </a>
                <span
                  className="group flex items-center gap-2 rounded-lg px-2.5 py-2 font-medium text-white transition hover:bg-secondary hover:text-white"
                  onClick={logout}
                >
                  <span className="text-secondary transition group-hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9"
                      />
                    </svg>
                  </span>
                  <span>Sign Out</span>
                </span>
                <div className="px-2.5 py-2">
                  <img src={footerlogo} alt="" className="h-5" />
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <!-- Main --> */}
        <main className="h-full flex-1 overflow-y-auto overflow-x-hidden bg-primary bg-hero bg-cover bg-center bg-no-repeat">
          <div className="h-[100vh] flex flex-col overflow-y-auto overflow-x-hidden rounded-t-2xl bg-neutral-100 lg:rounded-s-[3rem] lg:rounded-tr-none lg:py-1 2xl:py-8 lg:px-12 2xl:px-16 h-py-8">
            {children}
          </div>
        </main>
      </div>

      {/* <script src="assets/js/preline.js"></script>
        <script src="assets/js/tiny-slider.js"></script>
        <script src="assets/js/aos.min.js"></script>
        <script src="assets/js/scripts.js"></script> */}
    </div>
  );
};
