import { twMerge } from 'tailwind-merge';

export const SelectableItem = ({
  label,
  selected = false,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        'text-brand-1000 bg-transparent py-2 px-6 font-medium text-[16px]/[24px] border border-brand-1000 rounded-full box-border outline-none hover:bg-brand-1000 hover:text-white focus:bg-brand-1000 focus:text-white',
        className,
        selected && 'ring ring-primary/20',
      )}
    >
      {label}
    </button>
  );
};
