import { useState } from 'react';

import {
  ButtonCallNotes,
  ButtonFinish,
  ButtonGoBack,
  SelectableItem,
} from '../Shared';

const CLASS_NAME_POSITIVE_ANSWER =
  'hover:bg-[#5BE7CD] hover:border-[#5BE7CD] focus:bg-[#5BE7CD] focus:border-[#5BE7CD]';
const CLASS_NAME_NEGATIVE_ANSWER =
  'hover:bg-destructive-500 hover:border-destructive-500 focus:bg-destructive-500 focus:border-destructive-500';

const WhatHappened = ({
  setIndex,
  setHappenedIndex,
  happenedIndex,
  updateDialoutcomes,
  handleOpen3,
  formCallNotes,
  onChangePitchState,
  isDialOutcomesLoading,
}) => {
  const [happenedContent, setHappenedContent] = useState('');

  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <p className="text-brand-1000 text-center font-semibold text-[18px]/[24px]">
          What happened on your call?
        </p>
        <div className="mt-6 flex flex-wrap items-center justify-center gap-3">
          <SelectableItem
            label="Booked an appointment"
            onClick={() => {
              setIndex(2);
              setHappenedIndex(0);
              onChangePitchState(prev => ({ ...prev, visible: true }));
            }}
            className={CLASS_NAME_POSITIVE_ANSWER}
          />
          <SelectableItem
            label="Scheduled a callback"
            onClick={() => {
              setIndex(2);
              setHappenedIndex(1);
              onChangePitchState(prev => ({ ...prev, visible: true }));
            }}
            className={CLASS_NAME_POSITIVE_ANSWER}
          />
          <SelectableItem
            label="Send an email"
            onClick={() => {
              setIndex(7);
              setHappenedIndex(2);
              onChangePitchState(prev => ({ ...prev, visible: true }));
            }}
            className={CLASS_NAME_POSITIVE_ANSWER}
          />
        </div>
        <div className="mt-6 flex flex-wrap items-center justify-center gap-3">
          <SelectableItem
            label="Hung up"
            selected={happenedContent === 'Hung up'}
            onClick={() => {
              setHappenedContent('Hung up');
              setHappenedIndex(4);
            }}
            className={CLASS_NAME_NEGATIVE_ANSWER}
          />
          <SelectableItem
            label="Not interested"
            onClick={() => {
              setIndex(16);
              setHappenedIndex(5);
              onChangePitchState(prev => ({ ...prev, visible: true }));
            }}
            className={CLASS_NAME_NEGATIVE_ANSWER}
          />
        </div>
        <div className="mt-3 flex flex-wrap items-center justify-center gap-3">
          <SelectableItem
            label="No answer"
            selected={happenedContent === 'No answer'}
            onClick={() => {
              setHappenedContent('No answer');
              setHappenedIndex(6);
            }}
            className={CLASS_NAME_NEGATIVE_ANSWER}
          />
          <SelectableItem
            label="Do Not Call"
            onClick={() => {
              setIndex(18);
              setHappenedIndex(8);
              onChangePitchState(prev => ({ ...prev, visible: true }));
            }}
            className={CLASS_NAME_NEGATIVE_ANSWER}
          />
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            setIndex(0);
          }}
          disabled={isDialOutcomesLoading}
        />
        {(happenedIndex === 4 || happenedIndex === 6) && (
          <ButtonFinish
            onClick={() => {
              updateDialoutcomes(happenedIndex);
            }}
            disabled={isDialOutcomesLoading}
          />
        )}
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
          disabled={isDialOutcomesLoading}
        />
      </div>
    </>
  );
};

export default WhatHappened;
