import {
  ButtonCallNotes,
  ButtonGoBack,
  ButtonNext,
  SelectableItem,
} from '../../Shared';

const PITCH_VARIANTS = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

export const Pitch = ({
  pitchState,
  callNotes,
  onChangePitchState,
  handleOpenCallNotesModal,
  onChangeIndex,
}) => {
  const hasSelectedValue = pitchState.value !== null;

  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col items-center">
            <p className="text-brand-1000 font-semibold text-[18px]/[24px]">
              Did you have a chance
            </p>
            <p className="text-brand-1000 font-semibold text-[18px]/[24px]">
              to pitch the product?
            </p>
          </div>
          <div className="flex justify-center gap-3">
            {PITCH_VARIANTS.map(({ label, value }) => (
              <SelectableItem
                key={value}
                label={label}
                selected={value === pitchState.value}
                onClick={() => {
                  onChangePitchState(prev => ({ ...prev, value }));
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            onChangeIndex(1);
            onChangePitchState({ value: null, visible: false });
          }}
        />
        <ButtonNext
          onClick={() => {
            onChangePitchState(prev => ({ ...prev, visible: false }));
          }}
          disabled={!hasSelectedValue}
        />
        <ButtonCallNotes
          onClick={handleOpenCallNotesModal}
          hasBg={callNotes && callNotes !== 'null'}
        />
      </div>
    </>
  );
};
