import BackspaceIcon from '../../../assets/img/backspace.svg';
import DialPadIcon from '../../../assets/img/dial-pad.svg';
import DialPadActiveIcon from '../../../assets/img/dial-pad-active.svg';
import EditIcon from '../../../assets/img/edit.svg';
import LeadIcon from '../../../assets/img/lead.svg';
import MicroIcon from '../../../assets/img/micro.svg';
import MicroMuteIcon from '../../../assets/img/micro-mute.svg';
import NotesIcon from '../../../assets/img/notes.svg';
import OfflineIcon from '../../../assets/img/offline.svg';
import PhoneIcon from '../../../assets/img/phone.svg';
import ReloadIcon from '../../../assets/img/reload.svg';
import TrashIcon from '../../../assets/img/trash.svg';

const ICON_BY_NAME_MAP = {
  backspace: BackspaceIcon,
  'dial-pad': DialPadIcon,
  'dial-pad-active': DialPadActiveIcon,
  edit: EditIcon,
  lead: LeadIcon,
  micro: MicroIcon,
  'micro-mute': MicroMuteIcon,
  notes: NotesIcon,
  offline: OfflineIcon,
  phone: PhoneIcon,
  reload: ReloadIcon,
  trash: TrashIcon,
};

export const Icon = ({ alt = 'icon', name, ...props }) => {
  return <img alt={alt} src={ICON_BY_NAME_MAP[name]} {...props} />;
};
