import { CircularProgress } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';

import { USER_ROLE } from '../constants';
import { useAuthContext } from '../hooks';
import {
  CallerDashboard,
  CallerStats,
  CallProcessing,
  ForgotPassword,
  Login,
  ManagerDashboard,
  Register,
  Report,
  ResetPassword,
} from '../pages';
import { RootProvider } from '../providers';

const ROUTES_BY_USER_ROLE_MAP = {
  [USER_ROLE.USER]: (
    <>
      <Route path="/caller-dashboard" element={<CallerDashboard />} />
      <Route path="/call-processing" element={<CallProcessing />} />
    </>
  ),
  [USER_ROLE.MANAGER]: (
    <>
      <Route path="/mdashboard" element={<ManagerDashboard />} />
      <Route path="/caller-stats" element={<CallerStats />} />
      <Route path="/reports" element={<Report />} />
    </>
  ),
};

const NOT_FOUND_REDIRECT_BY_USER_ROLE_MAP = {
  [USER_ROLE.USER]: '/caller-dashboard',
  [USER_ROLE.MANAGER]: '/mdashboard',
};

export const Router = () => {
  const { isUserRoleLoading, hasUserRole, userRole } = useAuthContext();

  if (isUserRoleLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress size={120} sx={{ color: '#FFFFFF' }} />
      </div>
    );
  }

  return (
    <Routes>
      {hasUserRole ? (
        <Route element={<RootProvider />}>
          {ROUTES_BY_USER_ROLE_MAP[userRole]}
        </Route>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </>
      )}
      <Route
        path="/"
        element={
          <Navigate
            to={NOT_FOUND_REDIRECT_BY_USER_ROLE_MAP[userRole] ?? '/login'}
            replace
          />
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={NOT_FOUND_REDIRECT_BY_USER_ROLE_MAP[userRole] ?? '/login'}
            replace
          />
        }
      />
    </Routes>
  );
};
