const isNonNullableArray = array => array.every(Boolean);

export const Fetcher = ({
  payload = [],
  loading,
  loader,
  error,
  errorBoundary,
  render,
}) => {
  if (loading) return loader;
  if (error) return errorBoundary;
  if (!isNonNullableArray(payload))
    throw new Error('[Fetcher]: unexpected error');
  return render(payload);
};
