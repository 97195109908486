import { useEffect, useState } from 'react';

import { APP_STATE } from '../constants';
import { AppStateContext } from '../context';

export const AppStateProvider = ({ children }) => {
  const [appState, setAppState] = useState(APP_STATE.ONLINE);

  const handleOnline = () => setAppState(APP_STATE.ONLINE);
  const handleOffline = () => setAppState(APP_STATE.OFFLINE);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <AppStateContext.Provider value={{ appState }}>
      {children}
    </AppStateContext.Provider>
  );
};
