import {
  ButtonCallNotes,
  ButtonFinish,
  ButtonGoBack,
  SelectableItem,
} from '../Shared';

const DO_NOT_CALL_VARIANTS = [
  { label: 'Wrong Contact', value: 'Wrong Contact' },
  { label: 'Wrong Number', value: 'Wrong Number' },
  { label: 'Bad Number', value: 'Bad Number' },
  { label: 'Company not in business', value: 'Company not in business' },
  { label: 'No Longer with Company', value: 'No Longer with Company' },
  { label: 'Retired', value: 'Retired' },
  { label: 'Remove from List', value: 'Remove from List' },
  { label: 'Unsuitable company', value: 'Unsuitable company' },
  { label: 'Current customer', value: 'Current customer' },
];

const CommonDiscard = ({
  happenedIndex,
  updateDialoutcomes,
  dialOutcomeDetail,
  setDialOutcomeDetail,
  handleOpen3,
  formCallNotes,
  onChangePitchState,
  isDialOutcomesLoading,
}) => {
  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <p className="text-brand-1000 text-center font-semibold text-[18px]/[24px]">
          Why are you marking the prospect as Do Not Call?
        </p>
        <div className="cursor-pointer mt-6 flex flex-wrap items-center justify-center gap-3">
          {DO_NOT_CALL_VARIANTS.map(({ label, value }) => (
            <SelectableItem
              key={value}
              label={label}
              selected={value === dialOutcomeDetail}
              onClick={() => {
                setDialOutcomeDetail(value);
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            setDialOutcomeDetail('');
            onChangePitchState({
              value: null,
              visible: true,
            });
          }}
          disabled={isDialOutcomesLoading}
        />
        <ButtonFinish
          onClick={() => {
            updateDialoutcomes(happenedIndex);
          }}
          disabled={!dialOutcomeDetail || isDialOutcomesLoading}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
          disabled={isDialOutcomesLoading}
        />
      </div>
    </>
  );
};

export default CommonDiscard;
