import { useState } from 'react';

import { IconButton } from '../../Shared';

const DIAL_PAD_KEYS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '*',
  '0',
  '#',
];

export const DialPad = ({ action }) => {
  const [dialPadKeys, setDialPadKeys] = useState('');

  const handleClick = dialPadKey => {
    setDialPadKeys(prev => prev + dialPadKey);
    action(dialPadKey);
  };

  const handleClear = () => {
    setDialPadKeys(prev => prev.substring(0, prev.length - 1));
  };

  return (
    <div className="w-full pb-5 pt-9 flex flex-col items-center justify-center gap-3">
      <div className="w-60 flex items-center justify-between relative h-[26px]">
        <span className="text-center text-brand-1000 font-semibold text-[18px]/[26px] truncate w-full px-6">
          {dialPadKeys}
        </span>
        <IconButton
          buttonProps={{
            className: 'w-5 h-5 !absolute right-0',
            onClick: handleClear,
            disabled: !dialPadKeys,
          }}
          iconProps={{
            name: 'backspace',
            alt: 'bs',
            className: 'h-5',
          }}
        />
      </div>
      <div className="grid grid-cols-3 grid-rows-4 gap-3">
        {DIAL_PAD_KEYS.map(v => (
          <button
            key={v}
            onClick={() => {
              handleClick(v);
            }}
            className="rounded-[20px] w-[72px] h-16 bg-[#E6EDF3] text-brand-1000 font-medium text-[24px]/[36px]"
          >
            {v}
          </button>
        ))}
      </div>
    </div>
  );
};
