import { ButtonCallNotes, ButtonGoBack, ButtonNext } from '../Shared';

const Appointment = ({
  setIndex,
  setAppointment,
  handleOpen3,
  formCallNotes,
}) => {
  const handleSubmit = () => {
    setIndex(10);
  };
  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <p className="text-brand-1000 font-semibold text-[18px]/[24px] text-center">
          What type of appointment are you scheduling?
        </p>
        <form id="add-referral-form" className="mt-6" onSubmit={handleSubmit}>
          <div className="relative h-[62px]">
            <select
              name="name"
              id="appt_type_select"
              className="border-b-secondary/20 focus:border-b-secondary absolute bottom-0 flex peer h-[46px] border-b-2 border-x-transparent border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent focus:border-x-transparent focus:border-t-transparent focus:pb-2 focus:pt-2 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-2"
              onChange={e => {
                setAppointment(e.target.value);
              }}
            >
              <option value="Discovery Call">Discovery Call</option>
              <option value="Demo">Demo</option>
            </select>
            <label
              htmlFor="appt_type_select"
              className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
            >
              Appointment Type
            </label>
          </div>
        </form>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            setIndex(3);
          }}
        />
        <ButtonNext
          onClick={() => {
            setIndex(4);
          }}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </>
  );
};

export default Appointment;
