import {
  ButtonCallNotes,
  ButtonFinish,
  ButtonGoBack,
  SelectableItem,
} from '../Shared';

const NOT_INTERESTED_VARIANTS = [
  { label: 'No budget', value: 'No budget' },
  { label: 'Uses competitor', value: 'Uses competitor' },
  { label: 'Not a good time', value: 'Not a good time' },
  { label: 'No need for our service', value: 'No need for our service' },
];

const NotInterested = ({
  happenedIndex,
  updateDialoutcomes,
  handleOpen3,
  dialOutcomeDetail,
  formCallNotes,
  onChangePitchState,
  setDialOutcomeDetail,
  isDialOutcomesLoading,
}) => {
  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <p className="text-brand-1000 text-center font-semibold text-[18px]/[24px]">
          Why is the prospect not interested?
        </p>
        <div className="cursor-pointer mt-6 flex flex-col items-center justify-center gap-3">
          {NOT_INTERESTED_VARIANTS.map(({ label, value }) => (
            <SelectableItem
              key={value}
              label={label}
              selected={value === dialOutcomeDetail}
              onClick={() => {
                setDialOutcomeDetail(value);
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            setDialOutcomeDetail('');
            onChangePitchState({
              value: null,
              visible: true,
            });
          }}
          disabled={isDialOutcomesLoading}
        />
        <ButtonFinish
          onClick={() => {
            updateDialoutcomes(happenedIndex);
          }}
          disabled={!dialOutcomeDetail || isDialOutcomesLoading}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
          disabled={isDialOutcomesLoading}
        />
      </div>
    </>
  );
};

export default NotInterested;
