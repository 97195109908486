import { IconButton as MuiIconButton } from '@mui/material';
import { twMerge } from 'tailwind-merge';

import { Icon } from '../Icon';

const DEFAULT_BUTTON_CLASS_NAME =
  'w-[28px] h-[28px] !bg-transparent !p-0 !rounded-lg hover:rounded-lg hover:!bg-base-700 focus:!rounded-lg focus:!bg-base-700';

export const IconButton = ({ buttonProps, iconProps }) => {
  return (
    <MuiIconButton
      {...buttonProps}
      className={twMerge(DEFAULT_BUTTON_CLASS_NAME, buttonProps?.className)}
      disableFocusRipple={true}
      disableRipple={true}
    >
      <Icon {...iconProps} />
    </MuiIconButton>
  );
};
