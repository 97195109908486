import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { OfflineOverlay } from './components/Shared';
import { SENTRY_CONFIG } from './configs';
import { AppStateProvider, AuthProvider } from './providers';
import { Router } from './router';

import './App.css';
import './assets/css/aos.min.css';
import './assets/css/style.css';
import './assets/css/tailwind.css';
import './assets/css/tiny-slider.css';

Sentry.init(SENTRY_CONFIG);

const App = () => {
  return (
    <BrowserRouter>
      <AppStateProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
        <OfflineOverlay />
      </AppStateProvider>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
