import * as Sentry from '@sentry/react';
import { Device } from '@twilio/voice-sdk';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { APP_STATE, CALL_STATUS, TWILIO_CONFIG } from '../constants';
import { TwilioDeviceContext } from '../context';
import { useAppStateContext } from '../hooks';

export const TwilioDeviceProvider = ({ children }) => {
  const { appState } = useAppStateContext();
  const [isTwilioTokenLoading, setIsTwilioTokenLoading] = useState(true);
  const [isTwilioTokenError, setIsTwilioTokenError] = useState(false);
  const [twilioDevice, setTwilioDevice] = useState(null);
  const [twilioDeviceRegisterStatus, setTwilioDeviceRegisterStatus] =
    useState('');
  const [call, setCall] = useState(null);
  const [isDialPadVisible, setIsDialPadVisible] = useState(false);
  const [isMicroMute, setIsMicroMute] = useState(false);
  const [timer, setTimer] = useState(0);
  const [callStatus, setCallStatus] = useState(CALL_STATUS.RINGING);

  useEffect(() => {
    if (callStatus !== CALL_STATUS.ACCEPT) return;
    const interval = setInterval(() => setTimer(prev => prev + 1), 1000);

    return () => {
      clearInterval(interval);
    };
  }, [callStatus]);

  const createTwilioDevice = useCallback(async () => {
    if (twilioDevice || appState === APP_STATE.OFFLINE) return;

    setIsTwilioTokenLoading(true);
    setIsTwilioTokenError(false);

    try {
      const response = await fetch('/token');

      if (response.status !== 200) throw new Error();

      const data = await response.json();
      const device = new Device(data.token, TWILIO_CONFIG.DEVICE_OPTIONS);
      device.register();

      device.on('registering', () => {
        setTwilioDeviceRegisterStatus('registering');
      });

      device.on('registered', () => {
        setTwilioDeviceRegisterStatus('registered');
      });

      device.on('unregistered', () => {
        setTwilioDeviceRegisterStatus('unregistered');
      });

      device.on('tokenWillExpire', async () => {
        try {
          const response = await fetch('/token');

          if (response.status !== 200) throw new Error();

          const data = await response.json();
          device.updateToken(data.token);
        } catch {
          setIsTwilioTokenError(true);
          setTwilioDevice(null);
          setTwilioDeviceRegisterStatus('');
          toast.info("Can't update Device Access Token", {
            position: 'top-right',
            autoClose: 3000,
          });
        }
      });
      device.on('error', error => {
        Sentry.captureException(new Error(error));
        const errorMessage =
          error?.message ?? TWILIO_CONFIG.DEFAULT_ERROR_MESSAGE;
        toast.info(errorMessage, {
          position: 'top-right',
          autoClose: 3000,
        });
      });
      setTwilioDevice(device);
      setIsTwilioTokenLoading(false);
    } catch {
      setIsTwilioTokenLoading(false);
      setIsTwilioTokenError(true);
      toast.info("Can't get Device Access Token", {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  }, [twilioDevice, appState]);

  useEffect(() => {
    createTwilioDevice();

    return () => {
      if (!twilioDevice) return;
      twilioDevice.removeAllListeners();
      twilioDevice.destroy();
      setTwilioDevice(null);
      setTwilioDeviceRegisterStatus('');
    };
  }, [twilioDevice, createTwilioDevice]);

  const isCallAvailable =
    !!twilioDevice &&
    appState === APP_STATE.ONLINE &&
    twilioDeviceRegisterStatus === 'registered' &&
    !isTwilioTokenLoading &&
    !isTwilioTokenError;

  const handleMute = () => {
    if (!call) return;
    call.mute(!call.isMuted());
  };

  const handleSendDigits = digits => {
    if (!call) return;
    call.sendDigits(digits);
  };

  return (
    <TwilioDeviceContext.Provider
      value={{
        call,
        timer,
        callStatus,
        isMicroMute,
        twilioDevice,
        isCallAvailable,
        isDialPadVisible,
        isTwilioTokenError,
        isTwilioTokenLoading,
        twilioDeviceRegisterStatus,
        setCall,
        setTimer,
        handleMute,
        setCallStatus,
        setIsMicroMute,
        setTwilioDevice,
        handleSendDigits,
        createTwilioDevice,
        setIsDialPadVisible,
      }}
    >
      {children}
    </TwilioDeviceContext.Provider>
  );
};
