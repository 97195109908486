import { APP_STATE } from '../../../constants';
import { useAppStateContext } from '../../../hooks';
import { Icon } from '../Icon';

export const OfflineOverlay = () => {
  const { appState } = useAppStateContext();

  return (
    <div
      className={`fixed inset-0 items-center justify-center gap-4 bg-black/70 text-white text-lg ${appState === APP_STATE.ONLINE ? 'hidden' : 'flex'}`}
    >
      <Icon name="offline" alt="offline" className="h-12" />
      <span className="text-white font-medium text-[40px]/[48px]">
        You’re offline
      </span>
    </div>
  );
};
