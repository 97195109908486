import { twMerge } from 'tailwind-merge';

import { IconButton } from '../IconButton';

export const ButtonCallNotes = ({ onClick, disabled = false, hasBg }) => {
  return (
    <IconButton
      buttonProps={{
        className: twMerge(
          'w-12 h-12 justify-self-end',
          hasBg && '!bg-blue-300 hover:!bg-blue-300 focus:!bg-blue-300',
        ),
        onClick,
        disabled,
      }}
      iconProps={{ name: 'notes', alt: 'notes', className: 'h-8' }}
    />
  );
};
