import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from '../../assets/header/login/img/logo.png';
import LogoTo from '../../assets/header/login/img/logo-2.png';
import BasicModal from '../../components/Common/BasicModel';
import { LicenseModal } from '../../components/Modals';
import { ErrorMessage } from '../../components/Shared';
import { USER_ROLE, VALIDATION_SCHEMAS } from '../../constants';
import { useAuthContext } from '../../hooks';

import './css/app.css';
import './css/vendor.css';

export const Login = () => {
  const navigate = useNavigate();
  const { setUserRole } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, handleSubmit, getValues } = useForm({
    resolver: yupResolver(VALIDATION_SCHEMAS.LOGIN),
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSignIn = handleSubmit(async formData => {
    setIsSubmitting(true);
    try {
      const response = await fetch('/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      setIsSubmitting(false);

      if (response.status === 403) {
        openModal();
        return;
      }

      if (isModalOpen) closeModal();

      if (response.status === 200) {
        setUserRole(USER_ROLE.USER);
        navigate('/call-processing', { replace: true });
        return;
      }

      if (response.status === 302) {
        setUserRole(USER_ROLE.MANAGER);
        navigate('/mdashboard', { replace: true });
        return;
      }

      if (response.status === 401) {
        toast.error('Invalid username or password', {
          position: 'top-right',
          autoClose: 3000,
        });
        return;
      }

      throw new Error();
    } catch {
      setIsSubmitting(false);
      if (isModalOpen) closeModal();
      toast.error('Login failed', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  });

  const onAcceptLicenseAgreement = async () => {
    const formData = getValues();
    try {
      const response = await fetch('/accept-license-agreement', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.status !== 200) throw new Error();

      onSignIn();
    } catch {
      closeModal();
      toast.error('Login failed', {
        position: 'top-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      {/*// <!-- HEADER :: START-->*/}
      <nav className="header" id="header">
        <div className="c-grid-fluid">
          <div className="header__wrapper">
            <div>
              <div className="logo logo--header">
                <a className="logo__link" href="/">
                  <img className="logo__image" src={Logo} alt="Logotype" />
                </a>
              </div>
            </div>
            {/*<div>*/}
            {/*    <nav className="header__nav"><a href="#">IQ Accelerate</a><a href="#">IQ Boost</a><a*/}
            {/*        href="#">IQ Scale</a><a href="#">Pricing</a><a href="#">About</a></nav>*/}
            {/*</div>*/}
            <div>
              {/*<div className="header__btn c-btn__wrapper"><a class="c-btn" href="#">Book a Demo</a></div>*/}
              {/*<div className="hamburger hamburger--squeeze" role="button" hamburger-js="hamburger-js">*/}
              {/*    <div className="hamburger-box">*/}
              {/*        <div className="hamburger-inner"></div>*/}
              {/*    </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </nav>
      <main className="main main--start p-sign-in">
        <section className="sign" id="sign">
          <div className="c-grid">
            <div className="section__wrapper">
              <div className="c-section" data-align="center">
                <div className="c-section__head">
                  <div className="logo logo--header">
                    <a className="logo__link" href="/">
                      <img
                        className="logo__image"
                        src={LogoTo}
                        alt="Logotype"
                      />
                    </a>
                  </div>
                </div>
                <div className="c-section__body">
                  <h5 className="c-section__title">Log in to your account</h5>

                  <form
                    className="c-form"
                    autoComplete="off"
                    onSubmit={onSignIn}
                  >
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="email">
                        Email
                      </label>
                      <Controller
                        control={control}
                        name="username"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          const hasErrorMessage = !!error?.message;
                          return (
                            <>
                              <input
                                value={value}
                                onChange={onChange}
                                className="c-form__input"
                                id="email"
                                placeholder="Enter your email address"
                                autoComplete="username"
                              />
                              {hasErrorMessage && (
                                <ErrorMessage errorMessage={error.message} />
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="c-form__field">
                      <label className="c-form__label" htmlFor="password">
                        Password
                      </label>
                      <Controller
                        control={control}
                        name="password"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          const hasErrorMessage = !!error?.message;
                          return (
                            <>
                              <input
                                value={value}
                                onChange={onChange}
                                className="c-form__input"
                                type="password"
                                id="password"
                                placeholder="Enter your password..."
                                autoComplete="current-password"
                              />
                              {hasErrorMessage && (
                                <ErrorMessage errorMessage={error.message} />
                              )}
                            </>
                          );
                        }}
                      />
                    </div>
                    <div className="c-form__field c-form__field--btn">
                      <div className="c-btn__wrapper">
                        <input
                          className="c-btn"
                          type={'submit'}
                          data-btn-color="blue"
                          data-btn-size="full"
                          data-btn-fw="600"
                          data-btn-round="half"
                          value={'Log in'}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </form>
                  <p className="flex flex-col items-end p-2">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer" id="footer">
        <div className="c-grid">
          <div className="footer__wrapper"></div>
        </div>
      </footer>
      {isModalOpen && (
        <BasicModal open={isModalOpen}>
          <LicenseModal
            onSuccess={onAcceptLicenseAgreement}
            onCancel={closeModal}
          />
        </BasicModal>
      )}
    </div>
  );
};
