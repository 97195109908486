import styled from '@emotion/styled';
import { CircularProgress as MuiCircularProgress } from '@mui/material';
import tw from 'twin.macro';

const StyledCircularProgress = styled(MuiCircularProgress)(() => [
  tw`text-brand-1000`,
]);

export const CircularProgress = ({ size }) => {
  return <StyledCircularProgress size={size} />;
};
