import { CALL_STATUS } from '../../../constants';
import { Icon } from '../../Shared';

const getFormattedPhoneNumber = phoneNumber =>
  `+1 ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;

const getFormattedTimer = timer => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const time = `${formattedMinutes}:${formattedSeconds}`;

  return time;
};

export const CallProgress = ({ lead, callStatus, timer, isDialPadVisible }) => {
  const phoneNumber = getFormattedPhoneNumber(lead.bestPhone);

  const contentByCallStatus =
    callStatus === CALL_STATUS.ACCEPT ? getFormattedTimer(timer) : 'Ringing...';

  return isDialPadVisible ? (
    <div className="flex items-center justify-between gap-6 p-6 w-full border-b border-b-[#E6EDF3]">
      <div className="flex items-center gap-4 truncate">
        <Icon name="lead" alt="lead" className="h-[44px]" />
        <div className="flex flex-col gap-1 truncate">
          <span className="text-brand-1000 font-semibold text-[16px]/[21px] text-center truncate">
            {lead.contact}
          </span>
          <span className="text-base-1000 font-medium text-[14px]/[18px]">
            {phoneNumber}
          </span>
        </div>
      </div>
      <span className="text-base-800 font-normal text-[14px]/[20px] text-center">
        {contentByCallStatus}
      </span>
    </div>
  ) : (
    <div className="w-full flex flex-col gap-12 p-6">
      <Icon name="lead" alt="lead" className="h-[72px]" />
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1 truncate">
          <span className="text-brand-1000 font-semibold text-[24px]/[32px] text-center truncate">
            {lead.contact}
          </span>
          <span className="text-base-800 font-normal text-[16px]/[24px] text-center truncate">
            {lead.company}
          </span>
        </div>
        <div className="flex flex-col gap-1 truncate">
          <span className="text-base-1000 font-medium text-[18px]/[24px] text-center">
            {phoneNumber}
          </span>
          <span className="text-base-800 font-normal text-[16px]/[24px] text-center">
            {contentByCallStatus}
          </span>
        </div>
      </div>
    </div>
  );
};
