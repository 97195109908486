import BasicModal from '../../Common/BasicModel';
import { CircularProgress, SelectableItem } from '../../Shared';

const DISCARD_VARIANTS = [
  { label: 'Wrong Contact', value: 7 },
  { label: 'Company not in business', value: 7 },
  { label: 'Do not call', value: 7 },
  { label: 'Unsuitable company', value: 7 },
  { label: 'Current customer', value: 7 },
];

export const DiscardModal = ({
  isOpen,
  onClose,
  updateDialOutcomes,
  setHappenedIndex,
  isDialOutcomesLoading,
}) => {
  return (
    <BasicModal
      open={isOpen}
      handleClose={isDialOutcomesLoading ? undefined : onClose}
    >
      <div
        id="discard-modal"
        className="hs-overlay pointer-events-none fixed start-0 top-8 z-[60]  h-full w-full overflow-y-auto overflow-x-hidden focus-visible:outline-none"
      >
        <div className="m-3 mt-0  transition-all ease-out hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="pointer-events-auto flex flex-col rounded-xl border bg-white shadow-sm">
            <div className="flex items-center justify-between border-b-[2px] border-b-secondary/20 px-4 py-3">
              <h5 className="text-xl font-semibold">Reasons to discard</h5>
              <button
                onClick={onClose}
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-transparent text-sm font-semibold text-gray-800 transition hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                data-hs-overlay="#discard-modal"
                disabled={isDialOutcomesLoading}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="h-4 w-4 flex-shrink-0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            <div className="overflow-y-auto p-4">
              {isDialOutcomesLoading ? (
                <div className="flex items-center justify-center h-[274px]">
                  <CircularProgress size={72} />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center gap-4">
                  {DISCARD_VARIANTS.map(({ label, value }) => (
                    <SelectableItem
                      key={label}
                      label={label}
                      onClick={() => {
                        updateDialOutcomes(value, label);
                        setHappenedIndex(value);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};
