import { IconButton } from '../../../components/Shared';

export const PanelEditContact = ({ lead, action }) => {
  return (
    <div className="rounded-[20px] bg-white/30 py-3 pl-6 pr-5 flex gap-2">
      <div className="flex flex-col w-full truncate">
        <span className="text-brand-1000 font-semibold text-[18px]/[28px] truncate">
          {lead.contact}
        </span>
        <span className="text-base-800 font-normal text-[16px]/[24px] truncate">
          {lead.company}
        </span>
      </div>
      <IconButton
        buttonProps={{ onClick: action }}
        iconProps={{ name: 'edit', alt: 'edit', className: 'h-5' }}
      />
    </div>
  );
};
