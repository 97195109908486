import moment from 'moment';
import ReactDatePicker from 'react-datepicker';

import { ButtonCallNotes, ButtonGoBack, ButtonNext } from '../Shared';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/react-date-picker.css';

const CURRENT_DATE = moment();
const FUTURE_DATE = moment().add(5, 'year');
const MIN_DATE = CURRENT_DATE.toDate();
const MAX_DATE = FUTURE_DATE.toDate();

const Schedule1 = ({
  onChangeSelectedDate,
  onChangeFormObjContact,
  formObjContact,
  setIndex,
  selectedDate,
  handleOpen3,
  formCallNotes,
  onChangePitchState,
}) => {
  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <div className=" flex gap-6 flex-col items-center">
          <p className="text-brand-1000 font-semibold text-[18px]/[24px]">
            What day did you schedule the appointment for?
          </p>
          <ReactDatePicker
            selected={selectedDate.toDate()}
            onChange={date => {
              const nextSelectedDate = moment(date);
              onChangeSelectedDate(nextSelectedDate);
              onChangeFormObjContact({
                ...formObjContact,
                apptDate: nextSelectedDate.format('MMMM Do YYYY'),
              });
            }}
            minDate={MIN_DATE}
            maxDate={MAX_DATE}
            inline
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            calendarStartDay={1}
          />
        </div>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            onChangePitchState({
              value: null,
              visible: true,
            });
          }}
        />
        <ButtonNext
          onClick={() => {
            setIndex(3);
          }}
        />
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
        />
      </div>
    </>
  );
};

export default Schedule1;
