import moment from 'moment-timezone';

import {
  ButtonCallNotes,
  ButtonFinish,
  ButtonGoBack,
  ButtonNext,
} from '../Shared';

const Schedule3 = ({
  setIndex,
  toggleIsPlayer,
  timeInfo,
  pickerInfo,
  happenedIndex,
  appointment,
  updateDialoutcomes,
  handleOpen3,
  formCallNotes,
  isDialOutcomesLoading,
}) => {
  const getUserTimeZone = () => {
    const userTimeZone = moment.tz.guess(); // Get user's time zone
    const userTimeZoneAbbreviation = moment().tz(userTimeZone).format('z');
    return userTimeZoneAbbreviation;
    // Get abbreviation
    // setUserTimeZoneAbbreviation(userTimeZoneAbbreviation);
  };

  return (
    <>
      <div className="h-[386px] overflow-y-auto overflow-x-hidden rounded-[20px] bg-white/30 p-6 [&::-webkit-scrollbar-thumb]:bg-secondary/80 [&::-webkit-scrollbar-track]:bg-secondary/20 [&::-webkit-scrollbar]:w-2">
        <p className="text-brand-1000 font-semibold text-[18px]/[24px] text-center">
          Does the information below look correct?
        </p>
        <form action="" className="mt-6">
          <div className="space-y-4">
            <div className="relative">
              <input
                readOnly
                type="text"
                id="date"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Date"
                value={pickerInfo.format('MMMM Do YYYY')}
              />
              <label
                htmlFor="date"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Date
              </label>
            </div>
            <div className="relative">
              <input
                readOnly
                type="text"
                id="time"
                className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                placeholder="Time"
                value={`${timeInfo} ${getUserTimeZone()}`}
              />
              <label
                htmlFor="time"
                className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
              >
                Time
              </label>
            </div>
            {happenedIndex === 0 && (
              <div className="relative">
                <input
                  readOnly
                  type="text"
                  id="appointmentType"
                  className="peer block w-full border-b-2 border-x-transparent border-b-secondary/20 border-t-transparent bg-transparent px-0 py-4 text-xl font-medium transition placeholder:text-transparent autofill:pb-2 autofill:pt-6 focus:border-x-transparent focus:border-b-secondary focus:border-t-transparent focus:pb-2 focus:pt-6 focus:outline-none focus:ring-0 disabled:pointer-events-none disabled:opacity-50 [&:not(:placeholder-shown)]:pb-2 [&:not(:placeholder-shown)]:pt-6"
                  placeholder="Appointment Type"
                  value={appointment}
                />
                <label
                  htmlFor="appointmentType"
                  className="pointer-events-none absolute start-0 top-0 flex h-full origin-top-left items-center truncate border border-transparent px-0 font-medium transition duration-100 ease-in-out peer-focus:-translate-y-3 peer-focus:scale-90 peer-focus:text-gray-500 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-3 peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:text-gray-500"
                >
                  Appointment Type
                </label>
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="flex items-center justify-between rounded-[20px] bg-white/30 px-6 py-5">
        <ButtonGoBack
          onClick={() => {
            setIndex(happenedIndex === 0 ? 19 : 3);
          }}
          disabled={isDialOutcomesLoading}
        />
        {happenedIndex === 1 && (
          <ButtonFinish
            onClick={() => {
              updateDialoutcomes(happenedIndex);
            }}
            disabled={isDialOutcomesLoading}
          />
        )}
        {happenedIndex !== 1 && (
          <ButtonNext
            onClick={() => {
              setIndex(5);
              toggleIsPlayer('visible');
            }}
            title="Yes, looks good!"
          />
        )}
        <ButtonCallNotes
          onClick={handleOpen3}
          hasBg={formCallNotes && formCallNotes !== 'null'}
          disabled={isDialOutcomesLoading}
        />
      </div>
    </>
  );
};

export default Schedule3;
