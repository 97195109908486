import { useContext } from 'react';

import { AppStateContext } from '../context';

export const useAppStateContext = () => {
  const appStateContext = useContext(AppStateContext);

  if (!appStateContext) throw new Error('[AppStateContext]: unexpected error');

  return appStateContext;
};
